<template>
  <div>
    <b-card>
      <h2>
        Informacje o sprzęcie
      </h2>
    </b-card>

    <b-card>
      <h2>
        Nazwa: {{ equipment.name }}
      </h2>
      <br>

    </b-card>

    <b-card>
      <button
        class="btn btn-primary"
        style=" width: 100%;"
      >
        Edytuj
      </button>
      <button
        class="btn btn-danger"
        style="margin-top:10px; width: 100%;"
        @click="deleteEquipment ()"
      >
        Usuń
      </button>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BCard, BCardHeader, BCardBody, BCardFooter,
} from 'bootstrap-vue'
import swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
  },
  data() {
    return {
      equipment: {},
    }
  },
  mounted() {
    document.title = 'Informacje o sprzęcie'

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }

    axios.get(`${process.env.VUE_APP_API_URL}equipment/${this.$route.params.id}`, config)
      .then(response => {
        this.equipment = response.data
      })
  },
  methods: {
    deleteEquipment() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      swal.fire({
        title: 'Jesteś pewien?',
        text: 'Nie będzie można cofnąć tej operacji!',
        type: 'warning',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Tak, usuń!',
      }).then(result => {
        if (result.value) {
          axios.delete(`${process.env.VUE_APP_API_URL}equipment/${this.$route.params.id}`, config)
            .then(response => {
              swal.fire({
                title: 'Usunięto!',
                text: 'Sprzęt został usunięty.',
                icon: 'success',
                type: 'success',
                confirmButtonText: 'OK',
              })
              this.$router.push({ name: 'Equipment' })
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
  },
}
</script>

<style>

</style>
